<template>
  <div class="main">
    <el-card class="card">
      <div class="item">
        <el-row :gutter="10">
          <el-col :span="12">
            <div class="info-wrap">
              <div class="tit">
                <i class="el-icon-s-order icon"></i>
                中心介绍
                <span @click="goAboutus" class="more">更多</span>
                </div>
              <div class="desc">
                乌鲁木齐建设职业培训中心成立于1995年，是一家集教学、鉴定、实训于一体的综合性教育培训机构。本中心是自治区住房和城乡建设厅批准设立的二级建造师继续教育、三类人员继续教育、特种作业人员、施工现场专业人员、工人技能鉴定、工程质量检测试验人员、燃气从业人员教育培训机构，同时也是全疆施工安全“三类人员”新取证考点。
本中心是自治区人力资源和社会保障厅批准成立的乌鲁木齐市第二十职业技能鉴定所。是自治区人力和社会保障厅批准设立的工程类、经济类及其他委托专业的正高级、副高级继续教育培训基地，同时也是乌鲁木齐市人力资源和社会保障局批准设立的建筑类初级、中级、高级和市政类、水利类、环境保护类初级、中级专业技术人员继续教育基地；乌鲁木齐市专职安全生产管理人员继续教育点及乌鲁木齐市建委批准设立的建设行业特种作业人员新取证、复审培训基地。
除此之外，本中心还针对各相关企业开展自治区建设行业安全生产有关法律法规、安全生产管理人员、安全生产技术人员及安全生产警示教育的相关专业培训；建设行业企业内训等。
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="class-wrap">
              <div class="tit">
                <i class="el-icon-message-solid icon"></i>
                开课通知
                <span @click="gonoticeList" class="more">更多</span>
              </div>
              <!-- <ul v-if="token" class="tongzhi"> -->
              <ul class="tongzhi">
                <li 
                  v-for="item in tongzhiData" 
                  :key="item.id"
                  @click="goArtcile(item.id)"
                >
                  <p class="title">{{item.title}}</p><span>{{item.publishTime}}</span>
                </li>
              </ul>
              <!-- <div v-else class="tips">登录后，掌握最新开课通知！<router-link to="/login" class="login">去登录</router-link></div> -->
            </div>
          </el-col>
        </el-row>
        <!-- <em>【{{item.noticeType === '2' ? '公告' : '通知'}}】</em>{{item.noticeTitle}}<span>{{item.date}}</span> -->
        <!-- <h3>主体模块start</h3> -->
        <!-- <p>内容</p> -->
      </div>
      
    </el-card>
  </div>
</template>
 
<script>
import { mapGetters } from 'vuex'
// import { getNoticeList } from '@/api/common'
import { getArticle } from '@/api/index'
export default {
  name: 'News',
  data() {
    return {
      tongzhiData: [
        /* {
          id: 1,
          noticeType: '2',
          noticeTitle: '2022年度自治区警务专业技术人员继续培训通知',
          date: '2022-12-30'
        } */
      ]
    }
  },
  computed: {
    ...mapGetters([
      'token',
    ])
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() { //1 通知 2 公告
      const obj = {
        // noticeType: 1,
        categoryId: 5, // 5	招生通知
        page: 1,
        size: 5
      }
      getArticle(obj).then(res =>{
        if(res.data.code === 0) {
          console.log(res);
          this.tongzhiData = res.data.data.rows
          console.log(this.tongzhiData);
        }
      })
    },
    goAboutus() {
      this.$router.push({
        path: '/aboutus'
      })
    },
    gonoticeList() {
      this.$router.push({
        path: '/news',
        query:{
          type:'news',
          tab: '5'
        }
      })
    },
    goArtcile(id) {
      this.$router.push({
        path: '/article',
        query:{
          newsId:id,
          type: 'news'
        }
      })
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.main{
  width: 1170px;
  margin: 20px auto;

  .card{
    box-shadow: 0 0 1px #eee;
    margin-top: 30px;
    background: url('https://xiaozancloud-1303208826.cos.ap-guangzhou.myqcloud.com/digital-marketing-web/index/hot_re_Bg.png') center bottom no-repeat #fff;
    .item{
      height: 210px;
      // text-align: left;
      h3{
        font-weight: bold;
        text-align: left;
      }
      p{
        text-align: left;
      }
      .info-wrap{
        .tit{
          text-align: left;
          font-size: 18px;
          font-weight: bold;
          // color: #5c94d8;
          .icon{
            color: #5c94d8;
          }
          .more{
            float: right;
            color: #666;
            font-size: 14px;
            font-weight: normal;
            padding-right: 6px;
          }
          :hover{
            cursor: pointer;
            color:#5c94d8;
          }
        }
        .desc{
          /* margin-top: 10px; */
          padding: 10px 0px 10px 0px;
          text-indent: 2em;
          font-size: 14px;
          line-height: 30px;
          color: #333;
          text-align: left;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5;
          line-height: 36px;
          height: 173px;
        }
      }
      .class-wrap{
        .tit{
          text-align:left;
          font-size: 18px;
          font-weight: bold;
          // color: #5c94d8;
          padding-left: 20px;
          .more{
            float: right;
            color: #666;
            font-size: 14px;
            font-weight: normal;
            padding-right: 6px;
          }
          .icon{
            color: #5c94d8;
          }
          :hover{
            cursor: pointer;
            color:#5c94d8;
          }
        }
        .tongzhi{
          margin-top: 10px;
          padding-left: 20px;
          overflow: hidden;
          height: 184px;
          li{
            text-align: left;
            font-size: 14px;
            height: 36x;
            line-height: 36px;
            overflow: hidden;
            border-bottom: 1px #ddd dotted;
            em{
              // margin-left: 34px;
            }
            span{
              float:right;
              color: #999;
            }
            .title{
              float: left;
              width:390px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          li:hover{
            cursor: pointer;
            color:#5c94d8;
          }

        }
        .tips{
          margin-top: 20px;
          color:#666;
          text-align: left;
          margin-left: 20px;
          .login{
            color:#5c94d8;
          }
          .login:hover{
            color:#f60;
            text-decoration: none;
          }
        }
      }
    }

  }
}
</style>