<template>
  <div class="header">
    <div class="wrapin header_new" style="position: relative">
      <a href="" class="logo">

        <img
            src='../assets/logo4.png'
            alt=""
            class="logoimg"
        />
       <!-- <span class="ft1">  乌鲁木齐建设职业培训中心</span> -->
      </a>
      <!-- <div class="head_search">
        <form action="">
          <input type="text" name="keywords" class="text" placeholder="请输入课程关键字">
          <input type="submit" value title="点击搜索" class="sub">
        </form>
      </div> -->
      <div class="apps" style="position: absolute; right: 0px; top: 73px">
        <ul>
          <!-- <li>
            <img
              src="https://stu.xadi.com.cn/static/default/img/index/live.svg"
              alt
              class="ico"
              style="width: 29px"
            />
            <span class="title">品质课程</span>
          </li> -->
<!--          <li @click="goClassRoom">-->
<!--            <i class="iconfont  icon-zaixiankaoshi"></i>-->
<!--            <span class="title">模拟考试</span>-->
<!--          </li>-->

<!--          <li-->
<!--            @mouseenter="onEnter"-->
<!--            @mouseleave="onLeave"-->
<!--            id="webWeixinHover"-->
<!--            style="position: relative"-->
<!--          >-->
<!--            <i class="iconfont icon-qiyeweixin"></i>-->
<!--            <span class="title">企业微信</span>-->

<!--          <div :class="isShow === true ? 'show code' : 'code'">-->
<!--              <img-->
<!--                src="https://www.wlmqcol.com/files/system/block_picture_1588624630.jpg"-->
<!--                alt=""-->
<!--                style="width: 100%"-->
<!--              />-->
<!--            </div>-->
<!--          </li>-->

          <li
            @mouseenter="onEnter3"
            @mouseleave="onLeave3"
            id="webWeixinHover"
            style="position: relative"
          >
            <i class="iconfont icon-xiaochengxu"></i>
            <span class="title">小程序</span>
            <div :class="isShow3 === true ? 'show code' : 'code'">
              <img
                src="../assets/QRCodeIMG.jpg"
                alt=""
                style="width: 100%"
              />
            </div>
          </li>
          <li
              @mouseenter="onEnter2"
              @mouseleave="onLeave2"
              id="webWeixinHover"
              style="position: relative"
          >
            <i class="iconfont icon-gongzhonghao"></i>
            <span class="title">公众号</span>
            <div :class="isShow2 === true ? 'show code' : 'code'">
              <img
                  src="../assets/OpenAccountIMG.jpg"
                  alt=""
                  style="width: 100%"
              />
            </div>
          </li>
          <li
              @mouseenter="onEnter4"
              @mouseleave="onLeave4"
              id="webWeixinHover"
              style="position: relative"
          >
            <i class="iconfont icon-gongzhonghao"></i>
            <span class="title">服务号</span>
            <div :class="isShow4 === true ? 'show code' : 'code'">
              <img
                  src="../assets/ServiceAccountIMG.jpg"
                  alt=""
                  style="width: 100%"
              />
            </div>
          </li>
          <li 
            id="webWeixinHover"
            @click="goAddress"
            style="position: relative"
          >
            <i class="iconfont icon-ditu"></i>
            <span class="title" >地图</span>
          </li>
          <!-- <li 
            @mouseenter="onEnter4"
            @mouseleave="onLeave4"
            id="webWeixinHover"
            style="position: relative"
          >
            <i class="iconfont icon-ditu"></i>
            <span class="title" >地图</span>
            <div :class="isShow4 === true ? 'show code map-class' : 'code'">
              <iframe src="https://test-html.www.wlmqcol.com/baidu-map.html" width="400" height="250" vspace="-500" hspace="-500"></iframe>
            </div>
          </li> -->
        </ul>
      </div>
      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  components: {
  },
  data() {
    return {
      isShow: false, //标记是否展示
      isShow2: false, //标记是否展示
      isShow3: false, //标记是否展示
      isShow4: false, //标记是否展示
    }
  },
  methods: {
    goClassRoom() {
      this.$router.push({
        path: '/classroom?parent=7&second=143'
      })
    },
    goAddress() {
      this.$router.push({
        path: '/aboutus',
        query: {
          param: 'map'
        }
      })
    },
    onEnter() {
      this.isShow = true
    },
    onLeave() {
      this.isShow = false
    },
    onEnter2(){
      this.isShow2 = true
    },
    onLeave2(){
      this.isShow2 = false
    },
    onEnter3(){
      this.isShow3 = true
    },
    onLeave3(){
      this.isShow3= false
    },
    onEnter4(){
      this.isShow4 = true
    },
    onLeave4(){
      this.isShow4= false
    },
  }
}
</script>

<!-- <style rel="stylesheet/scss" lang="scss" scoped>
.header {
    height: 110px;
    .wrapin {
      width: 1170px;
      margin-left: auto;
      margin-right: auto;
      .apps {
        float: right;
        position: relative;
        margin-top: -39px;
        ul {
          float: left;
          li {
            width: 60px;
            float: left;
            display: inline;
            margin-left: 10px;
            text-align: center;
            position: relative;
            font-size: 14px;
          }
        }
      }
    }
    .logo {
      float: left;
      margin-top: 24px;
    }
    a {
      cursor: pointer;
      color: #333;
      text-decoration: none;
      outline: none;
    }
    img {
      border: none;
      margin: 0 auto 8px;
    }
    .head_search {
      float: right;
      width: 292px;
      height: 34px;
      border: 2px solid #dedede;
      border-radius: 3px;
      margin-top: 35px;
      position: relative;
    }
}
</style> -->
<style scoped>
.header {
  background: #fff;
  height: 110px;
}
.wrapin {
  width: 1170px;
  margin-left: auto;
  margin-right: auto;
}
.header .logo {
  float: left;
  display: flex;
  align-items: center;
  font-size: 28px;
  /* margin-top: 25px; */
  color: #004e9f;
  font-weight: bold;
  background-image: linear-gradient(#0747b6, #06386a);

  background-clip: text;

  -webkit-background-clip: text;

  color: transparent;

}
.logoimg{
  /* width: 80px; */
  margin-right:20px;
  position: relative;
}
a {
  cursor: pointer;
  color: #333;
  text-decoration: none;
  outline: none;
}
img {
  border: none;
}
.head_search {
  float: right;
  width: 292px;
  height: 34px;
  border: 2px solid #dedede;
  border-radius: 3px;
  margin-top: 35px;
  position: relative;
}
form {
  display: block;
  margin-top: 0em;
}
.head_search .text {
  float: left;
  height: 34px;
  width: 280px;
  background: none;
  line-height: 34px;
  border: 0;
  padding-left: 12px;
}
input,
textarea {
  outline: none;
}
.head_search .sub {
  width: 55px;
  height: 34px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  background: url(https://stu.xadi.com.cn/static/default/img/index/sou.svg)
  no-repeat center center #004e9f;
}
input.sub,
label {
  border: none;
  cursor: pointer;
}
.apps {
  float: right;
  position: relative;
  margin-top: -39px;
}
.apps ul {
  float: left;
}
ul {
  list-style-type: none;
}
.apps li {
  width: 60px;
  float: left;
  display: inline;
  margin-left: 20px;
  text-align: center;
  position: relative;
}
li {
  display: list-item;
  text-align: -webkit-match-parent;
}
li:hover{
  cursor: pointer;

}
#webWeixinHover:hover {
  cursor: pointer;
}
.code {
  display: none;
  width: 120px;
  height: 120px;
  position: absolute;
  right: 0;
  top: 70px;
  z-index: 100;
  background: #fff;
  padding: 5px;
}
.show {
  display: block;
}
.apps li a,
.apps li i,
.apps li .title {
  display: block;
}
.apps li .title {
  overflow: hidden;
  padding-top: 5px;
  font-size: 14px;
}
div.clear {
  font: 0px Arial;
  line-height: 0;
  height: 0;
  overflow: hidden;
  clear: both;
}
.ft1{
  font-family: Xingkai SC ,Weibei SC;
}
</style>