<template>
  <div class="banner">
    <!-- <img src="	https://www.wlmqcol.com/files/system/block_picture_1662189676.jpg" /> -->
    <!--主体为swiper标签-->
    <!--属性 :options 绑定的是data中设置的swiper配置项-->
    <!--属性 ref 用于获取该dom元素，在计算属性computed中将被调用-->
    <!--属性 id 为swiper最外层容器设置css样式-->
    <swiper :options="swiperOption" ref="mySwiper" id="mySwiper"> 
      <!--必须的组件-->
      <!--每页幻灯片使用swiper-slide标签-->
      <!--幻灯片组件生成的标签自带.swiper-slide的类名，但单类名选择器设置的部分css(如宽高)将被覆盖-->
      <swiper-slide class="swiper_slide_item"
      v-for="(item, index) in bannerdata"
      :key="index"
        >
        <img :src= item.imgUrl />
      </swiper-slide>
    

      <!-- 可选的控件 -->
      <!--分页器-->
      <div class="swiper-pagination" slot="pagination"></div>
      <!--滚动条-->
      <div class="swiper-scrollbar" slot="scrollbar"></div>
      <!--前进后退按钮-->
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
    </swiper>
    <!--配置自定义的页面跳转按钮，to(page)为自定义方法，其内调用了swiper的内置方法-->
    <button class="btn_item" @click="to(1)">1</button>
    <button class="btn_item" @click="to(2)">2</button>
    <button class="btn_item" @click="to(3)">3</button>
    <!-- <div class="btn_wrap">
            <button @click="to(1)"></button>
            <button @click="to(2)"></button>
            <button @click="to(3)"></button>
        </div> -->
    <div class="ba_sd">
      <div class="wrapin">
        <div class="ba_slider" v-if="lists.length>0">
          <marquee width="1170px">
            <div class="marqueeitem" v-for="item in lists" :key="item.id" @click="goArticle(item.noticeId)"
              >【{{item.noticeType === '2' ? '公告' : '通知'}}】{{item.noticeTitle}}</div
            >
          </marquee>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import request from '@/utils/request' 
import { getNoticeList ,getImgDataList} from "@/api/common";
export default {
  name: "Banner",
  data() {
    return {
      getSliderData:true,
      //swiperOption：swiper配置项信息，需要绑定在swiper标签的 :option 属性中
      swiperOption: {
        //幻灯片放映方向
        direction: "horizontal", //horizontal ,vertical上面，通常不与左右按钮一同使用
        effect: "fade",
        //分页器配置项
        pagination: {
          el: ".swiper-pagination", //分页器的类名
          clickable: true, // 点击分页器跳切换到相应的幻灯片
          type: "bullets", //'bullets' | 'progressbar' | 'fraction' , //小圆点|进度条|数字页码
          dynamicBullets: true, //动态小圆点(type:'bullets'时)
          //自定义分页器，需设置样式
          renderBullet(index, className) {
            return `<span class="${className} swiper-pagination-bullet-custom">${
              index + 1
            }</span>`;
          },
        },

        //前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },

        //滚动条
        scrollbar: {
          el: ".swiper-scrollbar",
          hide: true,
        },

        //幻灯片播放配置项
        loop: false, //是否循环播放
        speed: 1000, // 发生页面切换动画时，动画的切换速度
        autoplay: {
          delay: 5000, // 幻灯片停留时间
          disableOnInteraction: false, // 用户操作swiper之后，是否禁止autoplay
          stopOnLastSlide: false, // 切换到最后一个slide时是否停止自动切换。（loop模式下无效）。
        },
        on: {
          slideChangeTransitionEnd: function () {
            // console.log(this.activeIndex); //每次切换结束时，在控制台打印现在是第几个slide
          },
        },
      },
      lists: [],
      bannerdata:[]
    };
  },
  created() {
    /* request({
            method: 'GET',
            url: 'course/category',
            params: {
                // id: 1,
                // level: '2', //标签等级（目前有1,2,3三种类型标签）
                // categoryType: '1' //分类类型（1 课程标签） 不传默认位1
                }
        }).then(res=>{
            console.log(res);
        }) */
  },
  //计算属性
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },

  //设置自定义的按钮，点击后将前往目标也页
  mounted() {
    this.getData();
    this.getbannerData()
  },
  methods: {
    getData() {
      //1 通知 2 公告
      const obj = {
        noticeType: 2,
        page: 1,
        size: 5,
      };
      getNoticeList(obj).then((res) => {
        if (res.data.code === 0) {
          this.lists = res.data.data.rows
          // setTimeout(()=>{})
        }
      });
    },
    to(index) {
      this.swiper.slideTo(index);
    },
    // 公告详情
    goArticle(id){
      this.$router.push({
        path: `/article?noticeId=${id}&type=notice`
      })
    }, 
    getbannerData() { 
      getImgDataList(1).then(res =>{
        if(res.data.code === 0) {
          this.bannerdata = res.data.data.rows;
         
        }
      })
    },

  },
};
</script>

<style rel="stylesheet/scss" lang="scss">
.banner {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  height: 400px;
  background-color: #eee;
  #mySwiper {
    width: 100%;
    background-color: aquamarine;
    .swiper_slide_item {
      width: 100%;
      height: 400px;
      background-color: #eee;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .swiper-slide
    #mySwiper
    /deep/
    .swiper-pagination-bullet-custom.swiper-pagination-bullet {
    width: 15px !important;
    height: 15px !important;
  }
  .btn_item {
    /*  margin:  0 4px;
    border: 1px solid #ddd;
    border-radius:4px;
    color: #666; */
  }
  .btn_wrap {
    /* position: absolute;
    bottom: 5px;
    left: 45%; */
  }
  .ba_sd {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 60;
    width: 100%;
    height: 35px;
    background: rgba(0, 0, 0, 0.4);
    .wrapin {
      width: 934px;
      margin-left: auto;
      margin-right: auto;
      .ba_slider {
        width: 100%;
        height: 35px;
        line-height: 35px;
        overflow: hidden;
        position: relative;
        left: 118px;
        marquee {
         display: inline-block;
          width: 1170px;
        }
        .marqueeitem {
          color: #fff;
          display: inline-block;
          max-width: 95%;
          line-height: 35px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-decoration: none;
          font-size: 14px;
          padding-right: 20px;
          &:hover{
            color: #e24a32;
          }
        }
      }
    }
  }
}
</style>