<template>
  <div class="main">
    <div class="title">
      <!-- <i class="icon" :class="icon"></i> -->
      <i class="icon1"></i>
      <i class="icon2"></i>
      <i class="icon3"></i>
      {{ title }}
      <i class="icon4"></i>
      <i class="icon5"></i>
      <i class="icon6"></i>
      <!-- <span @click="handleMore" class="more">更多</span> -->
    </div>
    <div class="cont">
      <div class="tips">
        <dl class="item item1">
          <dt>01</dt>
          <dd style="font-weight: bold;">注册</dd>
          <dd>个人/企业注册<i></i></dd>
        </dl>
        <dl class="item item2">
          <dt>02</dt>
          <dd style="font-weight: bold;">选课</dd>
          <dd>选择课程提交支付<i></i></dd>
        </dl>
        <dl class="item item3">
          <dt>03</dt>
          <dd style="font-weight: bold;">缴费</dd>
          <dd>按付款指引支付费用<i></i></dd>
        </dl>
        <dl class="item item4">
          <dt>04</dt>
          <dd style="font-weight: bold;">学习</dd>
          <dd>完成课程学习<i></i></dd>
        </dl>
        <dl class="item item5">
          <dt>05</dt>
          <dd style="font-weight: bold;">考试</dd>
          <dd>参加考试完成学习<i></i></dd>
        </dl>
        <dl class="item item6">
          <dt>06</dt>
          <dd style="font-weight: bold;">发证</dd>
          <dd>通过学习获取证书<i></i></dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      required: true,
      type: String,
    },
    icon: {
      required: true,
      type: String,
    },
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.main{
  // width: 1170px;
  // margin: 20px auto;
  // background: url('../assets/yuming-bg.jpeg') center bottom no-repeat #fff;
  //background-size: 100% ;
  padding: 0px 0 20px;
  // margin-bottom: 20px;
  .title {
    text-align: center;
    font-size: 22px;
    font-weight: bold;

    padding-top: 10px;
    position: relative;
    background-image: linear-gradient(#1957c4, #031436);

    background-clip: text;

    -webkit-background-clip: text;

    color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon{
      color: #5c94d8;
      background:#18365b ;
      position: absolute;
      left: 50%;
      bottom: -10px;
      height: 2px;
      width:50px;
      margin-left: -25px;
      border-radius: 30px;
    }

    .more {
      float: right;
      color: #666;
      font-size: 14px;
      font-weight: normal;
      padding-right: 6px;
    }
    :hover {
      cursor: pointer;
      color: #b7090a;
    }
  }
  .cont{
    // background: #fff;
    padding: 20px;
    border-radius: 4px;
    margin-top: 20px;
    .tips{
      width: 990px;
      margin:0 auto 30px auto;
      height: 110px;
      border-bottom:1px solid #3f6de2;
      position: relative;
      .item{
        width: 248px;
        height: 100px;
        position: absolute;
        text-align: center;
        dt{
          height: 45px;
          line-height: 45px;
          font-size:32px;
          font-family:Impact;
          // color:#1957c4;
          background-image: linear-gradient(#1957c4, #bdc6d7);

          background-clip: text;

          -webkit-background-clip: text;

          color: transparent;
        }
        dd{
          height: 24px;
          line-height: 24px;
          color:#666;
        }
        i{
          display: block;
          width: 6px;
          height: 6px;
          background:#002ea5;
          border-radius:100%;
          position: absolute;
          left: 50%;
          bottom: -14px;
          margin-left:-3px;
        }
      }

      .item1{
        left: 0;
        margin-left:-124px;
      }
      .item2{
        left: 20%;
        margin-left:-124px;
      }
      .item3{
        left: 40%;
        margin-left:-124px;
      }
      .item4{
        left: 60%;
        margin-left:-124px;
      }
      .item5{
        left: 80%;
        margin-left:-124px;
      }
      .item6{
        left: 100%;
        margin-left:-124px;
      }
    }
  }
}
.icon1{
  border: 3px solid transparent;
  border-right: 3px solid #084590;
  width: 0px;
  height: 0px;
  display:inline-block;
  opacity: 0.5;
}
.icon2{
  border:4px solid transparent;
  border-right: 4px solid #084590;
  width: 0px;
  height: 0px;
  display:inline-block;
  opacity: 0.7;
}
.icon3{
  border:6px solid transparent;
  border-right: 6px solid #084590;
  width: 0px;
  height: 0px;
  display:inline-block;
  opacity: 0.9;
  margin-right: 10px;
}
.icon6{
  border: 3px solid transparent;
  border-left: 3px solid #084590;
  width: 0px;
  height: 0px;
  display:inline-block;
  opacity: 0.5;

}
.icon5{
  border: 4px solid transparent;
  border-left: 4px solid #084590;
  width: 0px;
  height: 0px;
  display:inline-block;
  opacity: 0.7;
}
.icon4{
  border: 6px solid transparent;
  border-left: 6px solid #084590;
  width: 0px;
  height: 0px;
  display:inline-block;
  opacity: 0.9;

  margin-left: 10px;
}
</style>