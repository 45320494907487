<template>
  <div  class="main2" >
    <div class="main">
      <div class="title">
        <!-- <i class="icon" :class="icon"></i> -->
        <i class="icon1"></i>
        <i class="icon2"></i>
        <i class="icon3"></i>
        {{ title }}
        <i class="icon4"></i>
        <i class="icon5"></i>
        <i class="icon6"></i>
        <!-- <span @click="handleMore" class="more">更多</span> -->
      </div>
      <div class="cont">
        <div class="item">
          <!-- <img src="https://stu.xadi.com.cn/static/default/img/index/ys1.png" alt="" class="ico" /> -->
          <i class="el-icon-s-promotion elIcon"></i>
          <dl>
            <dt>专业培训</dt>
            <dd>专注培训快30年，集教学、鉴定、</dd>
            <dd>实训于一体的综合性教育培训机构</dd>
          </dl>
        </div>
        <div class="item">
          <!-- <img src="https://stu.xadi.com.cn/static/default/img/index/ys2.png" alt="" class="ico" /> -->
          <i class="el-icon-setting elIcon"></i>
          <dl>
            <dt>优质资源</dt>
            <dd>精选优质教学课程，经过几十年磨砺，形成</dd>
            <dd>一套丰富、科学、合理、实用的教学资源</dd>
          </dl>
        </div>
        <div class="item">
          <!-- <img src="https://stu.xadi.com.cn/static/default/img/index/ys3.png" alt="" class="ico" /> -->
          <i class="el-icon-s-custom elIcon"></i>
          <dl>
            <dt>专业服务</dt>
            <dd>提供标准专业的服务、</dd>
            <dd>帮助学员成长</dd>
          </dl>
        </div>
        <div class="item">
          <!-- <img src="https://stu.xadi.com.cn/static/default/img/index/ys4.png" alt="" class="ico" /> -->
          <i class="el-icon-document elIcon"></i>
          <dl>
            <dt>考前培训</dt>
            <dd>提供大量的模拟考试训练，</dd>
            <dd>帮助学员稳固课程知识</dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      required: true,
      type: String
    },
    icon: {
      required: true,
      type: String
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.main {
  width: 1170px;
  margin: 20px auto;
  .title {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    margin-top: 30px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon{
      color: #5c94d8;
      background:#18365b ;
      position: absolute;
      left: 50%;
      bottom: -10px;
      height: 2px;
      width: 40px;
      margin-left: -20px;
      border-radius: 30px;
    }
  }
  .cont {
    /* background: #fff; */
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    padding:20px 0 20px;
    margin-top: 20px;
    .item{
      width: 276px;
      height: 162px;
      padding-top:24px;
      background:rgba(255,255,255,0.6);
      border-radius:8px;
      /* margin:0 10px; */
      /* float: left; */
      text-align: center;
      transition: all 0.2s ease-in;
      position: relative;
      overflow: hidden;
      &:hover{
        background: #18365b;
        color: #fff;
        dt{
          color: #fff;
          padding-top: 20px;
        }
        dd{
          color: #fff;
        }
        .elIcon{
          color: #fff;
          top:-50px;
        }
      }
      .ico{
        display: block;
        height: 40px;
        margin:0 auto 16px auto;
      }
      .elIcon{
        font-size: 40px;
        color:#18365b;
        margin-bottom: 10px;
        transition: all 0.2s ease-in;
        position: absolute;
        left: 50%;
        margin-left: -20px;
        top:20px;
      }
      dt{
        height: 30px;
        line-height: 30px;
        font-size:16px;
        color:#000;
        padding-top: 60px;
        transition: all 0.2s ease-in;
      }
      dd{
        color:#999;
        line-height: 24px;
        font-size:13px;
      }
    }
  }
}

.icon2{
  border:4px solid transparent;
  border-right: 4px solid #084590;
  width: 0px;
  height: 0px;
  display:inline-block;
  opacity: 0.7;
}
.icon3{
  border:6px solid transparent;
  border-right: 6px solid #084590;
  width: 0px;
  height: 0px;
  display:inline-block;
  opacity: 0.9;
  margin-right: 10px;
}
.icon6{
  border: 3px solid transparent;
  border-left: 3px solid #084590;
  width: 0px;
  height: 0px;
  display:inline-block;
  opacity: 0.5;

}
.icon5{
  border: 4px solid transparent;
  border-left: 4px solid #084590;
  width: 0px;
  height: 0px;
  display:inline-block;
  opacity: 0.7;
}
.icon4{
  border: 6px solid transparent;
  border-left: 6px solid #084590;
  width: 0px;
  height: 0px;
  display:inline-block;
  opacity: 0.9;

  margin-left: 10px;
}
</style>