<template>
  <div class="main">
    <div v-if="headerTooltip || receivedDataFromHeader" class="overlay"></div>
    <header-t :class="{ 'highlight': headerTooltip }" :showTooltip="headerTooltip"
              ref="headerChildComponent" @send-data="handleDataFromChild"/>
    <header2/>
    <classify-and-nav :class="{ 'highlight': receivedDataFromHeader }" ref="childComponent"
                      :dataFromHeader="receivedDataFromHeader" @send-data="tooltip2"/>
    <banner/>
    <news/>
    <study-step title="学习流程" icon="el-icon-s-help"/>
    <div class="main3">
      <class-list
          title="继续教育培训"
          parent="48"

          icon="el-icon-office-building"
      />
      <class-list title="新取证培训" parent="49" icon="el-icon-school"/>
      <class-list title="职称继续教育" parent="66" icon="el-icon-medal"/>
      <superiority title="平台优势" icon="el-icon-s-platform"/>
    </div>

    <div class="link_wrapBox">
      <div class="link_wrap">
        <div class="link">
          <div class="lintText"> 友情链接：</div>
          <a
              class="link-dark text-md"
              href="http://zjt.xinjiang.gov.cn"
              target="_blank"
          >新疆维吾尔自治区住房和城乡建设厅</a
          >
          <a
              class="link-dark text-md"
              href="http://jsy.xjjs.gov.cn"
              target="_blank"
          >新疆工程建设云</a
          >
          <a
              class="link-dark text-md"
              href="http://rst.xinjiang.gov.cn/"
              target="_blank"
          >新疆维吾尔自治区人力资源和社会保障厅</a
          >
          <a
              class="link-dark text-md"
              href="https://www.xjzcsq.com/"
              target="_blank"
          >新疆维吾尔自治区专业技术人员管理平台（职称管理系统）</a
          >
          <a
              class="link-dark text-md"
              href="http://www.wlmq.gov.cn/hrss.htm"
              target="_blank"
          >乌鲁木齐市人力资源和社会保障局</a
          >
          <a
              class="link-dark text-md"
              href="https://wlmq.xjzcsq.com/wlmq/index.html"
              target="_blank"
          >乌鲁木齐市职称评审系统</a
          >
          <a
              class="link-dark text-md"
              href="http://www.wlmqpxw.com"
              target="_blank"
          >乌鲁木齐人力资源培训平台</a
          >
          <a
              class="link-dark text-md"
              href="http://www.xjrsjxjy.com"
              target="_blank"
          >新疆继续教育网</a
          >
          <a
              class="link-dark text-md"
              href="http://www.xjrsks.com.cn"
              target="_blank"
          >新疆人事考试中心</a
          >
          <a
              class="link-dark text-md"
              href="http://www.wlmqrc.com/"
              target="_blank"
          >乌鲁木齐市人力资源和社会保障服务平台</a
          >
          <a
              class="link-dark text-md"
              href="http://www.xje.com.cn/"
              target="_blank"
          >新疆教育网</a
          >
        </div>
      </div>
    </div>
    <Footer/>
    <SideBar></SideBar>
    <!--    <div class="updateTips" v-if="showUpdataTip">-->
    <!--      <div class="t3"><span class="t3Bg"></span>系统升级通知</div>-->
    <!--      <div class="bg1"></div>-->
    <!--      <div class="bg2"></div>-->
    <!--      <div class="close" @click="hideUpdataTip">X</div>-->
    <!--      <div class="t1">各位学员：</div>-->
    <!--      <div class="t1">为响应主管部门对线上培训系统最新的技术要求，提高我中心信息化的发展水平，给广大学员提供更优质和快捷的服务，我中心将对线上培训系统进行升级。升级完成后所有的用户密码需要用注册的手机号找回，登陆账号为身份证号或绑定的手机号，如有问题可致电技术服务电话。-->
    <!--为此，给您带来的不便，我中心深表歉意！</div>-->
    <!--      <div class="t1" style="padding-top: 15px;">咨询电话：</div>-->
    <!--      <div class="t1">特种作业人员新取证及复审：<span class="t2">0991-2821187</span></div>-->
    <!--      <div class="t1">岗位人员新取证：<span class="t2">0991-2812728</span></div>-->
    <!--      <div class="t1">燃气从业人员新取证：<span class="t2">0991-3211888</span></div>-->
    <!--      <div class="t1">岗位、二建、安管人员继续教育：<span class="t2">0991-2825927</span></div>-->
    <!--      <div class="t1">水利安管人员新取证：<span class="t2">0991-2827027</span></div>-->
    <!--      <div class="t1">监理员新取证及继续教育：<span class="t2">0991-3211888</span></div>-->
    <!--      <div class="t1">试验员新取证及继续教育：<span class="t2">0991-3211888</span></div>-->
    <!--      <div class="t1">技术工人新取证：<span class="t2">0991-3211888</span></div>-->
    <!--      <div class="t1">自治区、乌鲁木齐市职称继续教育：<span class="t2">0991-8879298</span></div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import HeaderT from '@/components/HeaderT'
import Header2 from '@/components/Header'
import ClassifyAndNav from '@/components/ClassifyAndNav'
import Banner from '@/components/Banner'
import News from '@/components/News'
import ClassList from '@/components/ClassList'
import StudyStep from '@/components/StudyStep'
import Superiority from '@/components/Superiority'
import Footer from '@/components/Footer'
import SideBar from '@/components/SideBar'

export default {
  name: 'Index',
  components: {
    HeaderT,
    Header2,
    ClassifyAndNav,
    Banner,
    News,
    ClassList,
    StudyStep,
    Superiority,
    Footer,
    SideBar
  },
  data() {
    return {
      showUpdataTip: false,
      receivedDataFromHeader: false,
      headerTooltip: false
    }
  },
  created() {
    setTimeout(() => {
      this.showUpdataTip = true
    }, 2000)
  },
  methods: {
    hideUpdataTip() {
      this.showUpdataTip = false
    },
    handleDataFromChild(data) {
      // console.log(111)
      if(data === "next") {
        this.headerTooltip = false
        this.receivedDataFromHeader = true
        this.$refs.childComponent.showPopupTooltip();
      }else if (data === "close") {
        this.headerTooltip = false
        this.receivedDataFromHeader = false
      }else if (data === "start") {
        this.headerTooltip = true
        this.receivedDataFromHeader = false
      }
    },
    tooltip2(data){
      if(data === "previous"){
        this.headerTooltip = true
        this.receivedDataFromHeader = false
        this.$refs.headerChildComponent.showPopupTooltip();
      }else if(data === "confirm"){
        this.headerTooltip = false
        this.receivedDataFromHeader = false
      }
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.main {
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(85, 82, 82, 0.7);
    z-index: 10;
  }

  /* 高亮样式 */
  .highlight {
    position: relative;
    z-index: 20;
  }

  /* 其他元素置灰 */
  .main > *:not(.highlight) {
    filter: grayscale(100%);
  }

  height: 100%;
  background-color: #fff;

  .link_wrapBox {
    width: 100%;
    background: #313131;
    padding: 50px 0 0px;
  }

  .link_wrap {
    width: 1150px;
    margin: 0px auto;

    .lintText {
      color: #fff;
      font-size: 18px;
      text-align: left;
      margin-bottom: 10px;
    }

    .link {
      font-size: 14px;
      text-align: left;
      line-height: 30px;
      display: inline-block;

      a:link,
      a:visited {
        color: #ddd;
        text-decoration: none;
        margin-right: 20px;
        font-size: 14px;

        display: inline-block;
      }

      a:hover,
      a:active {
        color: #fff;
      }
    }
  }
}

.main3 {
  background: url('../../assets/play-bg.jpeg') left top repeat-y #fff !important;

  background-size: 100% 100%;
  width: 100%;
  padding-top: 10px;
}

.updateTips {
  font-size: 14px;
  width: 420px;
  padding: 10px 20px 20px;
  background: #fff;
  position: fixed;
  bottom: 10px;
  right: 10px;
  border-radius: 10px 10px 0 0;
  z-index: 100;
  text-align: left;
  overflow: hidden;
  box-shadow: 0px 0px 24px 1px rgba(0, 0, 0, 0.1);
  color: #313131;

  .t3 {
    height: 30px;
    line-height: 30px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 8px;
    padding-bottom: 10px;

    .t3Bg {
      width: 10px;
      height: 20px;
      display: inline-block;
      background: #5dd095;
      border-radius: 5px;
      margin-right: 10px;
      position: relative;
      top: 5px;
    }
  }

  .t1 {
    font-size: 14px;
    line-height: 24px;
  }

  .t2 {
    color: #333;
    font-weight: bold;
  }

  .bg1 {
    width: 350px;
    height: 350px;
    border-radius: 350px;
    position: absolute;
    right: -100px;
    top: -60px;
    background: #45a1e7;
    opacity: 0.2;
  }

  .bg2 {
    width: 150px;
    height: 150px;
    border-radius: 350px;
    position: absolute;
    left: 30px;
    bottom: -30px;
    background: #45a1e7;
    opacity: 0.1;
  }

  .close {
    position: absolute;
    width: 24px;
    height: 24px;
    background: #fff;
    right: 10px;
    top: 10px;
    cursor: pointer;
    line-height: 24px;
    text-align: center;
    border: 1px solid #999;
    color: #999;

  }

  .close:hover {
    border: 1px solid #4965d2;
    color: #4965d2;
  }
}

</style>