<template>
  <div :class="othreClass">
    <div class="main">
      <div class="title">
        <!--      <i :class="icon"></i>-->
        <i class="icon1"></i>
        <i class="icon2"></i>
        <i class="icon3"></i>
        {{ title }}
        <i class="icon4"></i>
        <i class="icon5"></i>
        <i class="icon6"></i>
        <i class="icon"></i>
      </div>
      <div class="card">
        <div class="item">
          <ul class="cont">
            <li @click="goDetail(item.id)" v-for="item in result" :key="item.id">
              <img class="img" :src="item.logo === '' ? defaultImg : item.logo"/>
              <div class="infobox">
                <p class="tit">{{ item.courseName }}</p>
                <div class="info">
                  <p v-if="item.endSigningTime">截止报名日期：{{ item.endSigningTime }}</p>
                </div>
                <div class="btn_wrap">
                  <div class="price">
                    <p v-if="item.price"><span style="font-size: 14px;">¥ </span>{{item.price}}</p>
                    <p v-else>免费</p>
                  </div>
                  <el-button round class="btn" @click="goCourse(item.id)"
                  >课程详情
                  </el-button
                  >
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="moreBox">
        <span @click="handleMore" class="more">查看更多</span>
      </div>
    </div>
  </div>
</template>

<script>
import {getCourseList} from "@/api/product";

export default {
  name: "ClassList",
  props: {
    parent: {
      required: true,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    icon: {
      required: true,
      type: String,
    },
    othreClass:{
      required: false,
      type: String,
    }
  },
  data() {
    return {
      defaultImg:
          "https://qiniu-cdn6.jinxidao.com/uploads/active/images/loading-238x184.png",
      result: [],
    };
  },
  methods: {
    goDetail(id) {
      this.$router.push({
        path: "/classdetail",
        query: {
          id,
        },
      });
    },
    handleMore() {
      let obj = {
        parent: this.parent * 1,
        recommend: 1, // 推荐位
      };
      console.log("this.parent", this.parent);
      this.$router.push({
        path: "/classroom",
        query: obj,
      });
    },
  },
  created() {
    let params = {
      categoryId: this.parent,
      page: 1,
      size: 8,
    };
    getCourseList(params).then((res) => {
      console.log(res);
      this.result = res.data.data.rows;
    });
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.main {
  width: 1170px;
  margin: 0px auto;
  padding: 0px 0 20px;
  .title {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: bold;
    margin-top: 30px;
    position: relative;
    background-image: linear-gradient(#1957c4, #031436);

    background-clip: text;

    -webkit-background-clip: text;

    color: transparent;
    .icon{
      color: #5c94d8;
      background:linear-gradient(to right,#f8f8f8,#164e93,#f8f8f8) ;
      position: absolute;
      left: 0%;
      bottom: -22px;
      height: 1px;
      width: 100%;
      border-radius: 30px;

    }

    :hover {
      cursor: pointer;
      color: #b7090a;
    }
  }
  .card {
    box-shadow: 0 0 1px #eee;
    padding:20px 0 20px;
    margin: 0;
    background:none;
    .item {
      margin-top: 20px;
      .cont {
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        li {
          width: 270px;
          background-color: #fff;
          padding: 0px;
          margin-bottom: 20px;
          box-sizing: border-box;
          margin-right: 30px;
          border-radius: 4px;
          transition: all 0.2s ease-in;
          position: relative;
          top: 0;
          border: 1px solid #eee;
          .img {
            width: 100%;
            height: 178px;
            overflow: hidden;

          }
          .infobox{
            padding: 5px 0 10px;
          }
          .tit{
            font-size: 16px;
            color:#333;
            text-align: left;
            margin-top: 4px;
            padding: 0 10px;
            font-weight: bold;
          }
          .info {
            color: #666;
            text-align: left;
            line-height: 23px;
            height: 23px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-size: 14px;
            margin: 5px 0;
            padding: 0 10px;
          }
          .btn_wrap{
            display: flex;
            align-items: center;
            justify-content:space-between;
            padding: 0 15px;
            .price{
              width: 50%;
              text-align: left;
              // text-indent: 20px;
              font-size: 20px;
              font-weight: bold;
              color:#626263;
            }
            .btn{
              border-radius:30px;
              padding: 6px 12px;
              font-size: 12px;

            }
          }
          .w100 {
            width: 100%;
          }
        }
        li:nth-child(4n) {
          margin-right: 0;
        }

        li:hover {
          top:-5px;
          box-shadow: 0px 10px 9px 0 #ddd;
          cursor: pointer;
          .btn{
            background: #084590;
            color: #fff;
          }
        }
      }
    }
  }
  .moreBox{
    text-align: right;
  }
  .more {
    color: #666;
    font-size: 14px;
    font-weight: normal;
    padding: 2px 15px;
    border: 1px solid #5c94d8;
    border-left: 3px solid #2e66aa;
    color: #18365b;
    display: inline-block;
    border-radius: 3px;
    line-height: 24px;
    height: 24px;
    cursor: pointer;
    transition: all 0.2s ease-in;
    background: #fff;
    font-size: 14px;
    position: relative;
    top:-15px;
    &:hover{
      background: #2e66aa;
      color: #fff;
    }
  }
}
.main2{
  background: url('../assets/play-bg.jpeg') left top no-repeat #fff !important;
  // background:  #fff !important;
  background-size: 100% 100%;
  width: 100%;
  padding-top: 10px;
  .item {
    li{
      border: 1px solid #eee;
    }
  }
}
.icon1{
  border: 3px solid transparent;
  border-right: 3px solid #084590;
  width: 0px;
  height: 0px;
  display:inline-block;
  opacity: 0.5;
}
.icon2{
  border:4px solid transparent;
  border-right: 4px solid #084590;
  width: 0px;
  height: 0px;
  display:inline-block;
  opacity: 0.7;
}
.icon3{
  border:6px solid transparent;
  border-right: 6px solid #084590;
  width: 0px;
  height: 0px;
  display:inline-block;
  opacity: 0.9;
  margin-right: 10px;
}
.icon6{
  border: 3px solid transparent;
  border-left: 3px solid #084590;
  width: 0px;
  height: 0px;
  display:inline-block;
  opacity: 0.5;

}
.icon5{
  border: 4px solid transparent;
  border-left: 4px solid #084590;
  width: 0px;
  height: 0px;
  display:inline-block;
  opacity: 0.7;
}
.icon4{
  border: 6px solid transparent;
  border-left: 6px solid #084590;
  width: 0px;
  height: 0px;
  display:inline-block;
  opacity: 0.9;

  margin-left: 10px;
}
</style>