<template>
  <div class="parent_wrap">
    <div class="class_and_nav">
      <!-- 产品分类 包裹层 -->
      <div class="class_wrap">
        <div class="title">课程导航</div>
        <ul class="nav_content">
          <li v-for="(item,index) in classLists" :key="item.id">
            <div class="tit" @click="handleOne(item.id)"><i :class="iconStyle[index]"></i>{{ item.categoryName }}</div>
            <span v-for="twoItem in item.rows" :key="twoItem.id">
              <span
                  @click="goClassroom(item.id, twoItem.id)"
                  class="two-title"
              >{{ twoItem.categoryName }}</span
              >
              <div class="submenu">
                <div class="leftdiv">
                  <!-- <p>{{item.rows}}</p> -->
                  <dl v-for="(threeItem, threeIndex) in item.rows" :key="threeItem.id">
                    <dt>
                      <span @click="goClassroom(item.id, item.rows[threeIndex].id)">{{ threeItem.categoryName }}</span>
                    </dt>
                    <dd>
                      <span
                          @click="goClassroom(item.id, threeItem.id, fourItem.id)"
                          v-for="fourItem in threeItem.rows"
                          :key="fourItem.id"
                      >{{ fourItem.categoryName }}</span
                      >
                      <!-- <span @click="goClassroom(twoItem.id,threeItem.id)" v-for="threeItem in twoItem.rows" :key="threeItem.id">{{threeItem.categoryName}}</span>  -->
                    </dd>
                  </dl>
                </div>
              </div>
            </span>
          </li>
          <!-- <li>
            <div class="tit">新取证</div>
            <a href="">八大员</a>
            <a href="">监理员</a>
            <a href="">见证取样</a>
            <a href="">监理安全</a>
            <a href="">三类人员</a>
            <a href="">试验检测员</a>
            <a href="">技术工人</a>
            <a href="">招标代理从业人员</a>
            <div class="submenu">
              <div class="leftdiv">
                <dl>
                  <dt><a href="#">八大员</a></dt>
                  <dd class="aaa">
                    <a href="#">免费</a> <a href="#">小说</a>
                    <a href="#">文学</a> <a href="#">经管</a>
                    <a href="#">励志与成功</a> <a href="#">畅销</a>
                    <a href="#">免费</a> <a href="#">小说</a>
                    <a href="#">文学</a> <a href="#">经管</a>
                    <a href="#">励志与成功</a> <a href="#">畅销</a>
                    <a href="#">免费</a> <a href="#">小说</a> <a href="#">文学</a>
                    <a href="#">经管</a>
                  </dd>
                </dl>
                <dl>
                  <dt><a href="#">监理员</a></dt>
                  <dd class="aaa">
                    <a href="#">免费</a> <a href="#">小说</a>
                    <a href="#">文学</a> <a href="#">经管</a>
                    <a href="#">励志与成功</a> <a href="#">畅销</a>
                    <a href="#">免费</a> <a href="#">小说</a>
                    <a href="#">文学</a> <a href="#">经管</a>
                    <a href="#">励志与成功</a> <a href="#">畅销</a>
                    <a href="#">免费</a> <a href="#">小说</a> <a href="#">文学</a>
                    <a href="#">经管</a>
                  </dd>
                </dl>
              </div>
            </div>
          </li>-->
        </ul>
      </div>
      <!-- 一级导航 -->
      <div class="nav">
        <ul>
          <li>
            <router-link to="/">首页</router-link>
          </li>
          <li>
            <router-link to="/classroom?parent=66">职称继续教育</router-link>
          </li>
          <li>
            <router-link to="/newlyCourse?isRegion=2">最新课程</router-link>
          </li>
          <li>
            <router-link to="/highQuality?isRegion=0">精品课程</router-link>
          </li>
          <li>
            <router-link to="/openCourse?isOpen=0">公开课</router-link>
          </li>
          <li>
            <router-link to="/liveCourse?isOpen=1">直播课</router-link>
          </li>
          <li>
            <router-link to="/classRegion?isRegion=1">地州培训</router-link>
          </li>
          <li>
            <router-link to="/news?type=news">资讯信息</router-link>
          </li>
        </ul>
        <div v-if="showPopup" class="popup">
          <div class="arrow-up"></div>
          <h4 class="popup-title">学习指引（二）</h4>  <!-- 新增的标题部分 -->
          <p>注册完成后请选择课程进行报名学习。</p>
          <div class="popup-buttons">
            <button @click="previousStep">上一步</button>
            <button @click="confirmPopup">好的</button>
          </div>
        </div>
      </div>
      <div class="nav_wrap">
        <a href="" class="hover">首页</a>
        <div class="dropdown">
          <span>开课通知</span>
        </div>
        <div class="dropdown">
          <span>最新开班</span>
        </div>
        <div class="dropdown">
          <span>问答</span>
        </div>
        <div class="dropdown">
          <span>题库自测</span>
          <div class="dropdown-content" id="test">
            <a href="" style="font-size: 16px">模拟考试</a>
            <a href="" style="font-size: 16px">习题练习</a>
          </div>
        </div>
        <div class="dropdown">
          <span>资质</span>
        </div>
        <div class="dropdown" style="float: right">
          <a href="" class="helpprobiem">常见问题</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'normalize.css/normalize.css'
// import request from '@/utils/request'
import {getClass} from '@/api/product.js'

export default {
  name: 'ClassAndBanner',
  props: {
    dataFromHeader: {
      type: [Boolean],  // 根据实际数据类型设置
      default: false
    }
  },
  data() {
    return {
      showPopup: this.dataFromHeader,
      iconStyle: ['el-icon-school icon', 'el-icon-office-building icon', 'el-icon-s-check icon', 'el-icon-s-order icon'],
      classLists: [
        // {
        //   categoryName:'考前培训',
        //   id:1,
        //   rows:[
        //     {
        //       categoryName:'岗位八大员',
        //       id:11,
        //       rows:[
        //         {
        //           categoryName:'市政施工员',
        //           id:111,
        //         },
        //         {
        //           categoryName:'安全员',
        //           id:112,
        //         }
        //       ]
        //     },
        //     {
        //       categoryName:'燃气从业人员',
        //       id:12,
        //       rows:[
        //         {
        //           categoryName:'企业主要负责人',
        //           id:121,
        //         },
        //         {
        //           categoryName:'运行维护和抢修人员',
        //           id:122,
        //         }
        //       ]
        //     }
        //   ]
        // },
        // {
        //   categoryName:'继续教育培训',
        //   id:2,
        //   rows:[
        //     {
        //       categoryName:'特种工',
        //       id:21,
        //       rows:[
        //         {
        //           categoryName:'司索信号工',
        //           id:211,
        //         }
        //       ]
        //     }
        //   ]
        // },
        // {
        //   categoryName:'职称继续教育',
        //   id:3,
        //   rows:[
        //     {
        //       categoryName:'干部院校',
        //       id:31,
        //       rows:[
        //       ]
        //     },
        //     {
        //       categoryName:'技工院校',
        //       id:32,
        //       rows:[
        //       ]
        //     },
        //     {
        //       categoryName:'经济系列',
        //       id:33,
        //       rows:[
        //       ]
        //     }
        //   ]
        // },
        // {
        //   categoryName:'模拟考试',
        //   id:4,
        //   rows:[
        //     {
        //       categoryName:'岗位八大员',
        //       id:41,
        //       rows:[
        //       ]
        //     },
        //     {
        //       categoryName:'建筑三类人员',
        //       id:42,
        //       rows:[
        //       ]
        //     },
        //     {
        //       categoryName:'水利三类人员',
        //       id:43,
        //       rows:[
        //       ]
        //     }
        //   ]
        // }
      ]
      /* classLists: [
        {
          categoryName:'分类1',
          id:1,
          rows:[
            {
              categoryName:'二级分类1',
              id:11,
              rows:[
                {
                  categoryName:'三级级分类1',
                  id:111,
                },
                {
                  categoryName:'三级级分类2',
                  id:112,
                }
              ]
            },
            {
              categoryName:'二级分类2',
              id:12,
              rows:[
                {
                  categoryName:'三级级分类3',
                  id:121,
                },
                {
                  categoryName:'三级级分类4',
                  id:122,
                }
              ]
            }
          ]
        },
        {
          categoryName:'分类2',
          id:2,
          rows:[
            {
              categoryName:'二级分类2',
              id:21,
              rows:[
                {
                  categoryName:'三级级分类2',
                  id:31,
                }
              ]
            }
          ]
        },
        {
          categoryName:'分类3',
          id:3,
          rows:[
            {
              categoryName:'二级分类3',
              id:31,
              rows:[
                {
                  categoryName:'三级级分类3',
                  id:311,
                },
                {
                  categoryName:'三级级分类4',
                  id:312,
                }
              ]
            }
          ]
        }
      ], // 课程数据 */
    }
  },
  mounted() {
    this.getClassData()
    this.handleTooltipNextClick(1);
  },
  methods: {
    handleTooltipNextClick(data) {
      if(data === 2) {
        //上一部
        this.$emit('send-data', "previous");
      }else if(data === 3) {
        //好的
        this.$emit('send-data', "confirm");
      }
    },
    previousStep() {
      this.showPopup = false;
      this.handleTooltipNextClick(2);
    },
    confirmPopup() {
      this.showPopup = false;
      this.handleTooltipNextClick(3);
    },
    showPopupTooltip() {
      this.showPopup = true;
    },
    //获取课程接口数据
    getClassData() {
      // request({
      //     method: 'GET',
      //     url: 'course/category',
      //     params: {
      //         // id: 1,
      //         // level: '2', //标签等级（目前有1,2,3三种类型标签）
      //         // categoryType: '1' //分类类型（1 课程标签） 不传默认位1
      //         }
      // }).then(res=>{
      //     if(res.status === 200) {
      //       this.classLists = res.data.data.rows
      //       console.log(this.classLists);
      //     }
      // })
      getClass().then((res) => {
        if (res.status === 200) {
          this.classLists = res.data.data.rows
          //classList去掉最后一条数据
          this.classLists.pop();
          console.log(this.classLists)
        }
      })
    },
    //跳转课程列表页面
    goClassroom(parent, second, three = null) {
      let obj = {}
      if (three === null) {
        obj = {
          parent,
          second: second
        }
      } else {
        obj = {
          parent,
          second: second,
          three: three
        }
      }
      console.log("objobjobjobj", obj)
      this.$router.push({
        path: '/classroom',
        query: obj
      })
    },
    handleOne(parentId) {
      let obj = {}
      obj = {
        parent: parentId
      }
      this.$router.push({
        path: '/classroom',
        query: obj
      })
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.parent_wrap {
  width: 100%;
  background-color: #004e9f;

  .class_and_nav {
    width: 1170px;
    height: 40px;
    margin: 0 auto;
    position: relative;
    z-index: 70;

    .class_wrap {
      width: 236px;
      position: absolute;
      left: 0;
      top: 40px;
      z-index: 70;
      height: 400px;
      background: #fff;
      opacity: 0.97;
      // border-radius: 4px;
      .title {
        display: none;
        height: 40px;
        line-height: 39px;
        color: #fff;
        font-size: 16px;
        border-radius: 3px 3px 1px 0px;
        background: url(https://stu.xadi.com.cn/static/default/img/index/daohang.svg) no-repeat 20px center #004e9f;
      }

      .nav_content {
        position: relative;
        display: block;

        li {
          padding: 10px 15px;
          font-size: 14px;
          text-align: left;
          line-height: 30px;
          font-size: 14px;
          color: #333;
          height: 80px;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box; //作为弹性伸缩盒子模型显示。
          -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
          -webkit-line-clamp: 3; //显示的行
          border-bottom: 1px solid #eee;

          :last-child {
            border-bottom: 0px;
          }

          .tit {
            height: 25px;
            line-height: 25px;
            font-size: 16px;
            font-weight: 800;
            text-align: left;

            .icon {
              color: #5c94d8;
              margin-right: 4px;
            }
          }

          .tit:hover {
            cursor: pointer;
          }

          .two-title {
            cursor: pointer;
            margin-right: 10px;
            display: inline-block;
          }

          .submenu {
            display: none;
            position: absolute;
            z-index: 4;
            left: 234px;
            top: 0px;
            width: 800px;
            min-height: 400px;
            /* border: 1px solid #ddd; */
            background: #f8f8f8;
            box-shadow: 0 0 1px #ddd;
            -moz-box-shadow: 0 0 1px #ddd;
            -webkit-box-shadow: 0 0 1px #ddd;
            text-indent: 0;

            .leftdiv {
              float: left;
              margin: 10px 30px 0px;
              // width: 500px;
              width: 90%;
              /* border:1px solid red; */
              dl {
                display: block;
                border-bottom: 1px solid #ddd;
                padding-bottom: 10px;
                overflow: hidden;
                line-height: 31px;
                margin-bottom: 10px;

                dt {
                  font-weight: bold;
                }

                dt:hover {
                  cursor: pointer;
                }

                dd {
                  cursor: pointer;

                  span {
                    margin-right: 10px;
                    color: #333;
                    display: inline-block;
                  }

                  span:hover {
                    color: #004e9f;
                  }
                }
              }
            }
          }

          a {
            color: #333;
            padding-right: 10px;
            text-decoration: none;
          }
        }

        :hover {
          background: #f8f8f8;
          color: #004e9f;
        }
      }

      .nav_content li:hover .submenu {
        display: block;
      }
    }

    .nav {
      ul {
        height: 40px;
        line-height: 40px;

        li {
          float: left;
          margin: 0 30px;
          font-weight: bold;
          font-size: 18px;

          a:link,
          a:visited {
            text-decoration: none;
            color: #fff;
          }

          a:hover {
            color: #9ccced;
          }
        }

        :hover {
          cursor: pointer;
          color: #5c94d8;
        }
      }
    }

    .popup-title {
      margin-top: 0;
      margin-bottom: 15px; /* 调整与内容之间的间隔 */
      font-size: 12px; /* 调整标题大小 */
      font-weight: bold;
      text-align: center; /* 让标题居中 */
      padding-bottom: 8px; /* 调整标题与下边框之间的间距 */
    }

    .arrow-up {
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #f9f9f9; /* 颜色与弹窗背景颜色相同 */
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translateX(-50%);
    }

    .popup {
      position: absolute;
      top: calc(100% + 10px);
      left: 28.33%;
      transform: translateX(-50%);
      font-size: 10px;
      width: 150px;
      background-color: #f9f9f9; /* 浅灰色背景 */
      border: 1px solid #ccc; /* 灰色边框 */
      padding: 5px;
      z-index: 1000;
      border-radius: 4px; /* 圆角 */
      line-height: 1.5;  /* 调整行高为1.5 */
    }

    .popup h4 {
      margin: 0;
      font-size: 12px;
      font-weight: bold;
    }

    .popup-buttons {
      margin-top: 4px;
      display: flex;
      justify-content: space-between;
    }

    button {
      padding: 5px 15px;
      border: none;
      border-radius: 4px; /* 按钮圆角 */
      cursor: pointer;
      background-color: #007BFF; /* 按钮背景颜色 */
      color: white; /* 文字颜色 */
    }

    button:hover {
      background-color: #0056b3; /* 鼠标悬浮时的颜色 */
    }

    .nav_wrap {
      display: none;
      float: left;
      padding-left: 250px;
      line-height: 40px;
      font-size: 16px;

      a {
        display: inline-block;
        padding: 0 20px;
        color: #666;
        text-decoration: none;
      }

      a:hover {
        color: #060606;
      }

      .helpprobiem {
        float: right;
        font-size: 14px;
        line-height: 40px;
        padding-left: 16px;
        background: url(https://stu.xadi.com.cn/static/default/img/index/helpa.png) no-repeat left center;
        margin-left: 300px;

        .dropdown {
          position: relative;
          display: inline-block;

          span {
            padding: 20px;
          }

          .dropdown-content {
            display: none;
            position: absolute;
            background-color: #f9f9f9;
            min-width: 160px;
            box-shadow: 0px 8px 16px 0px;
            padding: 12px 16px;
          }
        }
      }
    }
  }
}
</style>